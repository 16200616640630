import { useEnvironmentContext } from "../../../app/EnvironmentDataProvider";

/**
 * The first avatar in the list is used as the AI chat model.
 * TODO: Remove this once we have a proper way to select the AI chat model
 */
export const useGetAiVideoChatModel = () => {
  const { environment } = useEnvironmentContext();

  const aiVideoChatModel = environment.avatars.reduce(
    (max, obj) => (obj.sortIndex < max.sortIndex ? obj : max),
    environment.avatars[0]
  );

  return aiVideoChatModel;
};

export default useGetAiVideoChatModel;
