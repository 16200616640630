import { VIDEO_SIZE } from "../../lib/constants";
import AiVideoChatVideo from "./AiVideoChatVideo";
import { Props } from "./AiVideoChatVideo.ui";

const AiVideoChatVideoUiSmall: React.FC<Props> = (props) => {
  return (
    <AiVideoChatVideo
      {...props}
      height={VIDEO_SIZE.SMALL.HEIGHT}
      width={VIDEO_SIZE.SMALL.WIDTH}
      right={16}
      isSmall
    />
  );
};

export default AiVideoChatVideoUiSmall;
