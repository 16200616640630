import { useEffect, useRef, useState } from "react";
import SimpleAudioMeter from "../../../domains/settings/subpages/settingsVideo/util/audioMeter";

export const useIsSpeaking = (audioStream: MediaStream | null) => {
  const audioMeterRef = useRef<SimpleAudioMeter | null>(null);
  const [isSpeaking, setIsSpeaking] = useState(false);

  useEffect(() => {
    if (!audioStream || audioMeterRef?.current) return;
    const audioMeter = new SimpleAudioMeter(audioStream);
    audioMeterRef.current = audioMeter;
    audioMeter.start((volume) => {
      if (volume > 0.03) {
        setIsSpeaking(true);
      } else {
        setIsSpeaking(false);
      }
    });
    return () => {
      audioMeter.stop();
      audioMeterRef.current = null;
    };
  }, [audioStream]);

  return isSpeaking;
};

export default useIsSpeaking;
