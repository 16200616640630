import { SvgProps } from "../../types/svg";

const SadIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_b_617_17)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99999 3.15002C9.6844 3.15002 9.42856 3.40586 9.42856 3.72145V16.2929C9.42856 16.6085 9.6844 16.8643 9.99999 16.8643C10.3156 16.8643 10.5714 16.6085 10.5714 16.2929V3.72145C10.5714 3.40586 10.3156 3.15002 9.99999 3.15002ZM6 6.0072C6 5.6916 6.25584 5.43577 6.57143 5.43577C6.88702 5.43577 7.14286 5.6916 7.14286 6.00719V14.0072C7.14286 14.3228 6.88702 14.5786 6.57143 14.5786C6.25584 14.5786 6 14.3228 6 14.0072V6.0072ZM12.8571 8.29287C12.8571 7.97728 13.113 7.72144 13.4286 7.72144C13.7441 7.72144 14 7.97728 14 8.29287V11.7214C14 12.037 13.7441 12.2929 13.4286 12.2929C13.113 12.2929 12.8571 12.037 12.8571 11.7214V8.29287Z"
        fill="currentColor"
        className="fill-color"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_617_17"
        x="-190"
        y="-192.85"
        width="400"
        height="405.714"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="98" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_617_17"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_617_17"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SadIcon;
