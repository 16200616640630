import { styled } from "styled-components";
import { steps } from "../../../../app/style/theme";
import { getImageUrl } from "../../../../common/util/gyarados.util";
import CardContentAlignment from "../../../../componentsLibrary/atoms/CardContentAlignment";
import Image from "../../../../componentsLibrary/atoms/Image";
import Space from "../../../../componentsLibrary/atoms/Space";
import CardFullLandscape from "../../../../componentsLibrary/molecules/CardFullLandscape";
import useGetPollElements from "../../../../domains/poll/hooks/useGetPollElements";
import AiVideoChatAvatarImage from "../../login/AiVideoChatAvatarImage";
import { Props } from "./AiVideoChatPoll.ui";

// We need this padding in order for shadows to be visible
// Otherwise, the shadow is clipped by the Scroll component
const PollContainer = styled.div`
  padding-left: 32px;
  padding-right: max(var(--safe-margin-right), 64px);
`;

// Because of the padding, we need to nudge the Header Component to the right, so it looks centered
export const NudgeRight = styled.div`
  margin-left: ${steps.spacing[6]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const AvatarWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const AiVideoChatPollLandscape: React.FC<Props> = ({
  handleDismiss,
  poll,
  aiVideoChatModel,
  testId,
  ...rest
}) => {
  const isLeftAlign = true;
  const { Header, Body, Button, Title, Subtitle, ErrorMessage } =
    useGetPollElements({ poll, handleDismiss, isLeftAlign, ...rest });
  const pollImage = poll?.image;

  return (
    <CardFullLandscape
      testId={testId}
      topSection={
        pollImage ? (
          <Image
            src={getImageUrl(pollImage, 700, 700) ?? ""}
            width="100%"
            height="100%"
            cover
          />
        ) : (
          <NudgeRight>
            <AvatarWrapper>
              <AiVideoChatAvatarImage aiVideoChatModel={aiVideoChatModel} />
            </AvatarWrapper>
            {Header}
          </NudgeRight>
        )
      }
      onClose={poll?.dismissible ? handleDismiss : undefined}
    >
      <Space h={9} />
      <CardContentAlignment>
        <PollContainer>
          {Title}
          <Space h={5} />
          {Subtitle}
          {Body}
          {Button}
          <Space h={9} />
          {ErrorMessage}
        </PollContainer>
      </CardContentAlignment>
    </CardFullLandscape>
  );
};

export default AiVideoChatPollLandscape;
