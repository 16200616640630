/* IMPORTANT
   All top level routes in lugia are reserved for
   environment names or organisation names.
   All other routes needs to be prefixed with an underscore "_" to
   protect the namespace.
*/

export const PATH = {
  root: "/",
  experience: "/:orgSlug?/:envSlug",
  localExperience: "/_local/:orgSlug?/:envSlug",
  logout: "/_logout/:orgSlug?/:envSlug",
  ended: "/_ended/:orgSlug?/:envSlug",
  dev: "/_dev",
  devExperience: "/_dev/:orgSlug?/:envSlug",
  iframeDevExperience: "/_dev/iframe/:orgSlug?/:envSlug",
  iframeDevComponentExample: "/_dev/iframe/component/:orgSlug?/:envSlug",
  devtoolsEnvironment: "/_debug/:orgSlug?/:envSlug",
  iframeTestEnvironment: "/_test/iframe/messages/:orgSlug?/:envSlug",
  devtools: "/_debug",
  talebook: "/_talebook",
  talebookCatalog: "/_talebook/:orgSlug?/:envSlug",
  talebookPreview: "/_taleview/:orgSlug?/:envSlug",
  aiWebcamDemoLabs: "/lens",
  aiVideoChat: "/aiVideoChat/:orgSlug?/:envSlug",
  aiVideoChatEnded: "/aiVideoChat/_ended/:orgSlug?/:envSlug",
  diamond: "/diamond",
  diamondXYZ: "/diamond-xyz",
  dataExternal: "/_data/external/:orgSlug",
} as const;
type PathKeys = keyof typeof PATH;
export type Path = (typeof PATH)[PathKeys];
