import { styled } from "styled-components";
import { steps } from "../../../../app/style/theme";
import { getImageUrl } from "../../../../common/util/gyarados.util";
import Image from "../../../../componentsLibrary/atoms/Image";
import Space from "../../../../componentsLibrary/atoms/Space";
import Spatial from "../../../../componentsLibrary/atoms/Spatial";
import Card from "../../../../componentsLibrary/molecules/Card";
import useGetPollElements from "../../../../domains/poll/hooks/useGetPollElements";
import AiVideoChatAvatarImage from "../../login/AiVideoChatAvatarImage";
import { Props } from "./AiVideoChatPoll.ui";

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const PollContainer = styled.div`
  padding: 0 ${steps.spacing[8]};
`;

const AvatarWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -1px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const AiVideoChatPollNormal: React.FC<Props> = ({
  handleDismiss,
  poll,
  aiVideoChatModel,
  testId,
  ...rest
}) => {
  const pollImage = poll?.image;
  const { Header, Body, Button, Title, Subtitle, ErrorMessage } =
    useGetPollElements({ poll, handleDismiss, ...rest });

  return (
    <Wrapper>
      <Spatial rim glare mouseZoom mouseTiltX mouseTiltY dragMotion>
        <Card
          maxHeight="500px"
          testId={testId}
          onClose={poll?.dismissible ? handleDismiss : undefined}
          topSection={
            <>
              {pollImage ? (
                <Image
                  src={getImageUrl(pollImage, 700, 700) ?? ""}
                  width="100%"
                  height="200px"
                  cover
                />
              ) : (
                <PollContainer>
                  <Space h={8} />
                  <AvatarWrapper>
                    <AiVideoChatAvatarImage
                      aiVideoChatModel={aiVideoChatModel}
                    />
                  </AvatarWrapper>
                  {Header}
                </PollContainer>
              )}
              <PollContainer>
                <Space h={4} />
                {Title}
                <Space h={2} />
                {Subtitle}
                {Body}
                {Button}
                <Space h={2} />
                {ErrorMessage}
                <Space h={8} />
              </PollContainer>
            </>
          }
        />
      </Spatial>
    </Wrapper>
  );
};

export default AiVideoChatPollNormal;
