import React from "react";
import { AvatarResponseDto } from "@journee-live/gyarados/axios/esm";
import { useTheme } from "styled-components";
import { getImageUrl } from "../../../common/util/gyarados.util";
import Image from "../../../componentsLibrary/atoms/Image";

export type PropsLoginPageUi = {
  aiVideoChatModel?: AvatarResponseDto;
  size?: React.CSSProperties["height"];
  borderWidth?: React.CSSProperties["borderWidth"];
};

const AiVideoChatAvatarImage: React.FC<PropsLoginPageUi> = ({
  aiVideoChatModel,
  size = "72px",
  borderWidth = "2px",
}) => {
  const imageUrl = getImageUrl(aiVideoChatModel?.avatarImage, 200, 200) || "";

  const theme = useTheme();

  return (
    <Image
      src={imageUrl}
      width={size}
      height={size}
      styleOverrides={{
        border: borderWidth + " solid " + theme.colorBelow0,
        borderRadius: "50%",
      }}
    />
  );
};

export default AiVideoChatAvatarImage;
