import { useTheme } from "styled-components";
import { steps } from "../../../app/style/theme";
import { useIsLandscape, useIsSmallScreen } from "../../../common/hooks/ui";
import Hide from "../../../componentsLibrary/atoms/Hide";
import Row from "../../../componentsLibrary/atoms/Row";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";
import { useText } from "../../../domains/language/language.hook";
import Ellipsis from "../component/Ellipsis";
import useGetAiVideoChatModel from "../hooks/useGetAiVideoChatModel";
import AiVideoChatAvatarImage from "./AiVideoChatAvatarImage";

const layoutLandscape = {
  title: {
    size: "32px",
    lineHeight: "3.25rem",
  },
  body: {
    size: steps.font.f20.size,
  },
  avatar: {
    size: "40px",
    borderWidth: "2px",
  },
};

const layoutPortrait = {
  title: {
    size: "48px",
    lineHeight: "3.25rem",
  },
  body: {
    size: steps.font.f20.size,
  },
  avatar: {
    size: "72px",
    borderWidth: "2px",
  },
};

const layoutNormal = {
  title: {
    size: "96px",
    lineHeight: "6.5rem",
  },
  body: {
    size: steps.font.f40.size,
  },
  avatar: {
    size: "120px",
    borderWidth: "4px",
  },
};

export type PropsLoginPageUi = {
  isDismissed?: boolean;
  isMainButtonLoading?: boolean;
  hideStatus: boolean;
};

const AiVideoChatLoginAvatar: React.FC<PropsLoginPageUi> = ({
  isDismissed,
  isMainButtonLoading,
  hideStatus,
}) => {
  const aiVideoChatModel = useGetAiVideoChatModel();
  const isSmallScreen = useIsSmallScreen();
  const isLandscape = useIsLandscape();
  const isSmallLandscape = isSmallScreen && isLandscape;

  const theme = useTheme();
  const t = useText();

  const layout = isSmallScreen
    ? isLandscape
      ? layoutLandscape
      : layoutPortrait
    : layoutNormal;

  return (
    <Hide
      hide={isDismissed || !aiVideoChatModel}
      width="100%"
      height="100%"
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {isSmallLandscape ? (
        <>
          <Space h={6} />
          <Row width="100%" justify="center">
            {" "}
            <AiVideoChatAvatarImage
              aiVideoChatModel={aiVideoChatModel}
              size={layout.avatar.size}
              borderWidth={layout.avatar.borderWidth}
            />
            <Space w={3} />
            <Typo.Body
              size={layout.title.size}
              color={theme.colorBelow2}
              lineHeight={layout.title.size}
            >
              {aiVideoChatModel?.name}
            </Typo.Body>
          </Row>
          <Space h={2} />
        </>
      ) : (
        <>
          <Space h={9} />
          <AiVideoChatAvatarImage
            aiVideoChatModel={aiVideoChatModel}
            size={layout.avatar.size}
            borderWidth={layout.avatar.borderWidth}
          />
          <Space h={4} />
          <Typo.Body
            size={layout.title.size}
            color={theme.colorBelow2}
            lineHeight={layout.title.size}
          >
            {aiVideoChatModel?.name}
          </Typo.Body>
          <Space h={4} />
        </>
      )}
      <Typo.Body color={theme.colorBelow0} size={layout.body.size}>
        {isMainButtonLoading ? (
          <span style={{ position: "relative" }}>
            {t("ai_video_chat_connecting")}
            <span
              style={{
                position: "absolute",
                left: "100%",
                width: "14px",
              }}
            >
              <Ellipsis />
            </span>
          </span>
        ) : (
          !hideStatus && (
            <Typo.Body color={theme.colorBelow0} size={layout.body.size}>
              {t("ai_video_chat_available")}
            </Typo.Body>
          )
        )}
      </Typo.Body>
    </Hide>
  );
};

export default AiVideoChatLoginAvatar;
