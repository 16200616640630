import { useReflow } from "../../../../common/hooks/ui";
import AiVideoChatVideoUiNormal from "./AiVideoChatVideo.ui.normal";
import AiVideoChatVideoUiSmall from "./AiVideoChatVideo.ui.small";

export type Props = {
  hide: boolean;
  webcamMuted: boolean;
  micMuted: boolean;
  setWebcamMuted: (value: boolean) => void;
  audioConstraints: MediaTrackConstraintSet;
  videoConstraints: MediaTrackConstraintSet;
  isSpeaking: boolean;
  setPermissions: (value: { camera: boolean; microphone: boolean }) => void;
  onUserMedia?: () => void;
  height?: MediaTrackConstraintSet["height"];
  width?: MediaTrackConstraintSet["width"];
  right?: number;
  isSmall?: boolean;
};

const AiVideoChatVideoUi: React.FC<Props> = (props) => {
  return useReflow({
    normal: <AiVideoChatVideoUiNormal {...props} />,
    small: <AiVideoChatVideoUiSmall {...props} />,
  });
};

export default AiVideoChatVideoUi;
