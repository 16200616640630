import { useEffect } from "react";
import { useStore } from "../../app/store";

const AiVideoChatLogic: React.FC = () => {
  const isGameReady = useStore((s) => s.gameConnection.gameIsReady);
  const { openPanel, closePanel } = useStore((s) => s.layout);

  useEffect(() => {
    if (isGameReady) {
      openPanel("aiVideoChatActionBar");
      openPanel("aiVideoChatVideo");
      closePanel("profile");
    }
  }, [closePanel, isGameReady, openPanel]);

  return null;
};

export default AiVideoChatLogic;
