import { useCallback, useEffect, useRef, useState } from "react";
import styled, { css, keyframes, useTheme } from "styled-components";
import tinycolor from "tinycolor2";
import { useFirefoxFix } from "../../__hacks/useFirefoxFix";
import useIsAiVideoChat from "../../__labs/aiVideoChat/hooks/useIsAiVideoChat";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { useWebsocketConnection } from "../../app/gameConnection/gameConnectionHooks";
import {
  getFitStreamInViewport,
  startVideo,
} from "../../app/gameConnection/gameConnectionUtils";
import useAudio from "../../app/gameConnection/useAudio";
import useKeyboard from "../../app/gameConnection/useKeyboard";
import useScreenResize from "../../app/gameConnection/useScreenResize";
import { useWebRtcContext } from "../../app/gameConnection/webrtc/WebRtc.provider";
import { registerMouseEvents } from "../../app/gameConnection/webrtc/helpers/inputs";
import { useWebRtcPlayer } from "../../app/gameConnection/webrtc/webRtcConnection";
import { sendGameMessage } from "../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { useStore } from "../../app/store";
import { zIndex } from "../../app/style/theme";
import { isTouch } from "../../common/constants/flags.constant";
import { useIsLandscape, useIsSmallScreen } from "../../common/hooks/ui";
import { useDiamondDemo } from "../../common/hooks/useDiamondDemo";
import { logError, logInfo, logWarn } from "../../common/util/logger";
import useInitializeAvatar from "../profile/component/useInitializeAvatar";
import { useIsAutoplayBlockedHook } from "./lib/useIsAutoplayBlocked.hook";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }`;

const Player = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: ${zIndex.experience};
  display: flex;
  align-items: center;
`;

const Video = styled.video<{
  $connected?: boolean;
  $applyFilter: boolean;
  $fitViewport: boolean;
  $isSmall: boolean;
}>`
  ${(p) =>
    p.$fitViewport
      ? "width: 100dvw;"
      : !p.$isSmall
        ? css`
            width: 100%;
            height: 100%;
            object-fit: cover;
          `
        : css`
            width: 100%;
            border-radius: 24px;
            object-fit: cover;
          `};

  /* Firefox fix: aka I almost cry for one week.
   It fixes the shuffled frames on the video stream in Firefox windows issue
   https://bugzilla.mozilla.org/show_bug.cgi?id=1861895. */
  ${(p) =>
    p.$applyFilter
      ? css`
          filter: hue-rotate(360deg);
        `
      : ""}
  transition:
    opacity 0.6s,
    filter 0.4s,
    transform 0.4s;

  opacity: 0;

  /* We reveal the streaming with a delay, to avoid an abrupt switch between
  login background video and video streaming on fast loads. */

  ${(p) =>
    p.$connected &&
    css`
      animation: ${fadeIn} 1s ease-out 2s 1 forwards;
    `}
`;

const ClickRipples = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
`;

const VideoWrapper = styled.div<{
  $fitViewport: boolean;
  $isSmall: boolean;
}>`
  ${(p) =>
    p.$fitViewport
      ? css`
          width: 100dvw;
        `
      : css`
          width: 100%;
          height: 100%;

          ${p.$isSmall &&
          css`
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 144px;
            padding-right: 144px;
          `}
        `};
`;

const GamePlayer: React.FC = () => {
  const hasInteracted = useRef(false);
  const [playerElement, setPlayerEl] = useState<HTMLDivElement | null>(null);
  const [videoElement, setVideoEl] = useState<HTMLVideoElement | null>(null);
  const [playing, setPlaying] = useState(false);
  const [hasVideoLoaded, setHasVideoLoaded] = useState(false);
  const isBlocked = useIsAutoplayBlockedHook();
  const gameIsReady = useStore((s) => s.gameConnection.gameIsReady);
  const setClientInfo = useStore((s) => s.gameConnection.setClientInfo);
  const theme = useTheme();
  const isSmallScreen = useIsSmallScreen();
  const isAiVideoChat = useIsAiVideoChat();
  const isSmall = !isSmallScreen && isAiVideoChat;
  const webRtcContext = useWebRtcContext();
  const { selectedSpeakers } = useStore((s) => s.userMedia);

  // TODO: Temporary code, remove this once Diamond is done
  useDiamondDemo();

  const streamAudio = useAudio(videoElement);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const {
    environment: {
      forceTurnRelay,
      fitViewport: fitViewportConfig,
      hasPointerLock,
    },
  } = useEnvironmentContext();
  const isLandscape = useIsLandscape();
  const fitViewport = !isLandscape && getFitStreamInViewport(fitViewportConfig);

  const { addAudioStream, removeAudioStream, replaceAudioStream } =
    useWebRtcPlayer(
      playerElement,
      videoElement,
      streamAudio,
      forceTurnRelay || false
    );

  useWebsocketConnection();

  // Select speaker useEffects for AI Video Chat

  // Not sure why an audioStream only dependency doesn't trigger the useEffect
  const streamAudioId = (streamAudio?.srcObject as MediaStream)?.id;
  useEffect(() => {
    if (isAiVideoChat && audioRef.current && streamAudio) {
      if (!audioRef.current.paused) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }

      audioRef.current.srcObject = streamAudio.srcObject;
      audioRef.current.currentTime = streamAudio.currentTime;

      audioRef.current
        .play()
        .catch((err) =>
          logError("VOICE/VIDEO", "stream audio output playback error:", err)
        );
    }
  }, [streamAudioId, streamAudio, audioRef, isAiVideoChat]);

  useEffect(() => {
    const changeAudioOutput = async (selectedSpeakers?: MediaDeviceInfo) => {
      if (
        audioRef.current &&
        audioRef.current.setSinkId &&
        selectedSpeakers?.deviceId
      ) {
        try {
          await audioRef.current.setSinkId(selectedSpeakers.deviceId);

          logInfo(
            "VOICE/VIDEO",
            `Stream audio output changed to device ID: ${selectedSpeakers.label}`
          );
        } catch (error) {
          logError("VOICE/VIDEO", "Error setting stream audio output:", error);
        }
      } else {
        logWarn("VOICE/VIDEO", "setSinkId() not supported in this browser.");
      }
    };

    if (isAiVideoChat && selectedSpeakers?.deviceId) {
      changeAudioOutput(selectedSpeakers);
    }
  }, [isAiVideoChat, selectedSpeakers]);

  // CAREFUL. These 2 hooks must await for gameIsReady to function properly.
  // This because we need a video element with a given size to start registering mouse or resize events.
  useScreenResize(playerElement, videoElement, gameIsReady, hasVideoLoaded);
  useEffect(() => {
    if (!gameIsReady) return;
    if (!videoElement) return;
    // TEMP GET MIC AND ADD TO STREAM

    registerMouseEvents(videoElement, hasPointerLock);
  }, [addAudioStream, gameIsReady, videoElement, hasPointerLock]);

  useInitializeAvatar();
  useKeyboard();

  // Save theme colors to the store to send in the client info
  useEffect(() => {
    setClientInfo({
      isTouchDevice: isTouch,
      webBackgroundColor: tinycolor(theme.colorBelow2).toHexString(),
      webTextColor: tinycolor(theme.colorAbove5).toHexString(),
    });
  }, [theme.colorAbove5, theme.colorBelow2, setClientInfo]);

  const handleVideoClick = useCallback(() => {
    videoElement?.focus();
    if (playing && !hasInteracted.current) {
      hasInteracted.current = true;
      if (streamAudio.paused) {
        window.dispatchEvent(new CustomEvent("play-experience-audio"));
      }
    }
  }, [playing, streamAudio, videoElement]);

  const handlePlay = useCallback(async () => {
    startVideo(videoElement).then((isPlaying) => {
      sendGameMessage({
        type: "OnStreamIsShown",
      });
      window.analytics?.track("flow", {
        type: "flow",
        name: "connected",
      });
      setPlaying(isPlaying);
      sendGameMessage({
        type: "RequestQuestsInfo",
      });
    });
  }, [videoElement]);

  /**
   * If autoplay is blocked, we need to listen for a custom event
   * to start the video.
   */
  useEffect(() => {
    if (!isBlocked) return;
    window.addEventListener("play-experience-video", handlePlay, {
      once: true,
    });
    return () => {
      window.removeEventListener("play-experience-video", handlePlay);
    };
  }, [isBlocked, handlePlay]);

  /**
   * If autoplay is not blocked, we start the video.
   */
  useEffect(() => {
    if (!gameIsReady) return;
    if (!hasVideoLoaded) return;
    if (playing) return;
    if (isBlocked) return;
    handlePlay();
  }, [
    hasVideoLoaded,
    gameIsReady,
    playing,
    videoElement,
    isBlocked,
    handlePlay,
  ]);

  useEffect(() => {
    webRtcContext.setAddAudioStream(() => addAudioStream);
    webRtcContext.setRemoveAudioStream(() => removeAudioStream);
    webRtcContext.setReplaceAudioStream(() => replaceAudioStream);
  }, [addAudioStream, removeAudioStream, replaceAudioStream, webRtcContext]);

  // Firefox fix, bug reported on the next link https://bugzilla.mozilla.org/show_bug.cgi?id=1861895
  const { canvasRef, applyFilter } = useFirefoxFix();

  return (
    <Player ref={setPlayerEl}>
      {/* TODO: add this to the id dictionary */}
      {/* This canvas element gets removed after we check the vendor and renderer from the webgl api */}
      <canvas width={0} height={0} ref={canvasRef} id="canvas" />
      <VideoWrapper $fitViewport={fitViewport} $isSmall={isSmall}>
        <ClickRipples id="clickRipples" />
        <Video
          tabIndex={-1}
          onClick={handleVideoClick}
          id="streamingVideo"
          muted
          playsInline
          controls={false}
          ref={setVideoEl}
          $connected={playing}
          disablePictureInPicture
          $applyFilter={applyFilter}
          onLoadedMetadata={() => setHasVideoLoaded(true)}
          $fitViewport={fitViewport}
          $isSmall={isSmall}
        />
      </VideoWrapper>
      {isAiVideoChat && <audio ref={audioRef} id={"stream-audio"} />}
    </Player>
  );
};

export default GamePlayer;
