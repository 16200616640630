import { useReflow } from "../../common/hooks/ui";
import AiAvatarUiNormal from "./AiAvatar.ui.normal";

export type Props = {
  visible?: boolean;
  size?: React.CSSProperties["height"];
  left?: React.CSSProperties["left"];
  videoRef: React.RefObject<HTMLVideoElement>;
  isReady: boolean;
};

const AiAvatarUi: React.FC<Props> = (props) => {
  return useReflow({
    normal: <AiAvatarUiNormal {...props} />,
  });
};

export default AiAvatarUi;
