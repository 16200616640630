import { AvatarResponseDto } from "@journee-live/gyarados/axios/esm";
import { useReflow } from "../../../../common/hooks/ui";
import { Props as PollProps } from "../../../../domains/poll/Poll.ui";
import AiVideoChatPollLandscape from "./AiVideoChatPoll.ui.landscape";
import AiVideoChatPollNormal from "./AiVideoChatPoll.ui.normal";
import AiVideoChatPollPortrait from "./AiVideoChatPoll.ui.portrait";

export type Props = PollProps & {
  aiVideoChatModel: AvatarResponseDto;
};

const AiVideoChatPollUi: React.FC<Props> = (props) => {
  return useReflow({
    normal: <AiVideoChatPollNormal {...props} />,
    landscape: <AiVideoChatPollLandscape {...props} />,
    portrait: <AiVideoChatPollPortrait {...props} />,
  });
};

export default AiVideoChatPollUi;
