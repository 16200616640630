import { SvgProps } from "../../types/svg";

const LineIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5 11L1.5 9L18.5 9V11L1.5 11Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default LineIcon;
