import { useEffect, useState } from "react";
import { useStore } from "../../../app/store";
import { logError } from "../../../common/util/logger";

export const useAudioInputStream = () => {
  const { selectedMicrophone } = useStore((s) => s.userMedia);
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    const getAudioStream = async () => {
      const stream = await navigator.mediaDevices
        .getUserMedia({
          audio: { deviceId: { exact: selectedMicrophone?.deviceId } },
        })
        .catch((e) => {
          logError(
            "VOICE/VIDEO",
            "Failed to get Audio Input",
            selectedMicrophone?.label,
            e
          );
        });

      if (stream) setAudioStream(stream);
    };
    getAudioStream();
  }, [selectedMicrophone]);

  return audioStream;
};

export default useAudioInputStream;
