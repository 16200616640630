import { useCallback, useEffect, useMemo, useRef } from "react";
import Webcam from "react-webcam";
import styled, { useTheme } from "styled-components";
import { useStore } from "../../../app/store";
import { zIndex } from "../../../app/style/theme";
import useConferenceErrors from "../../../common/hooks/useConferenceErrors";
import HeaderBadge from "../../../componentsLibrary/atoms/HeaderBadge";
import Hide from "../../../componentsLibrary/atoms/Hide";
import Icon from "../../../componentsLibrary/atoms/Icon";
import Row from "../../../componentsLibrary/atoms/Row";
import Space from "../../../componentsLibrary/atoms/Space";
import SpeakingWidget from "../../../componentsLibrary/molecules/avatarCircle/SpeakingWidget";
import { useText } from "../../../domains/language/language.hook";
import ConferenceControlButton from "../../../domains/settings/subpages/settingsVideo/ConferenceControlButton";
import DeviceSelector from "../../../domains/settings/subpages/settingsVideo/DeviceSelector";
import {
  INDICATOR_SIZE,
  VIDEO_AVATAR_CIRCLE_SIZE,
} from "../../../domains/videoConference/lib/videoAvatars.constants";
import { useAiVideoChatContext } from "../AiVideoChat.provider";
import { useAudioInputStream } from "../hooks/useAudioInputStream";
import { useIsSpeaking } from "../hooks/useIsSpeaking";

const Container = styled.div<{ height?: string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const VideoWrapper = styled.div`
  border-radius: 100%;
  height: ${VIDEO_AVATAR_CIRCLE_SIZE.LARGE}px;
  width: ${VIDEO_AVATAR_CIRCLE_SIZE.LARGE}px;
  overflow: hidden;
  position: absolute;
  border: 4px solid ${(p) => p.theme.colorBelow2};
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpeakingIndicatorWrapper = styled.div`
  width: ${INDICATOR_SIZE.LARGE}px;
  height: ${INDICATOR_SIZE.LARGE}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 1px;
  bottom: 1px;
  z-index: ${zIndex.overlays + 1};
`;

const VideoAvatarWrapper = styled.div`
  height: ${VIDEO_AVATAR_CIRCLE_SIZE.LARGE}px;
  width: ${VIDEO_AVATAR_CIRCLE_SIZE.LARGE}px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AiVideoChatSettingsPanel: React.FC = () => {
  const t = useText();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const conferenceErrors = useConferenceErrors();
  const {
    setPermissions,
    selectedMicrophone,
    selectedCamera,
    selectedSpeakers,
    audioInputDevices,
    audioOutputDevices,
    videoInputDevices,
    setAudioInputDevices,
    setAudioOutputDevices,
    setVideoInputDevices,
    webcamMuted,
    micMuted,
    setWebcamMuted,
    setMicMuted,
    setSelectedCamera,
    setSelectedMicrophone,
    setSelectedSpeakers,
  } = useStore((s) => s.userMedia);
  const cameraErrorText = useMemo(() => {
    switch (true) {
      case conferenceErrors.includes("cameraAccessDenied"):
        return t("settings_no_camera_permission");
      case conferenceErrors.includes("noCameraAvailable"):
        return t("settings_no_valid_cam");
      case conferenceErrors.includes("cameraFeedError"):
        return t("settings_camera_feed_error");
      default:
        return undefined;
    }
  }, [conferenceErrors, t]);
  const { isInitialised, setIsInitialised } = useAiVideoChatContext();

  const audioStream = useAudioInputStream();
  const isSpeaking = useIsSpeaking(audioStream);

  const cameraDisabled =
    conferenceErrors.includes("cameraAccessDenied") ||
    conferenceErrors.includes("noCameraAvailable");

  const microphoneErrorText = useMemo(() => {
    switch (true) {
      case conferenceErrors.includes("microphoneAccessDenied"):
        return t("settings_no_microphone_permission");
      case conferenceErrors.includes("noMicrophoneAvailable"):
        return t("settings_no_valid_mic");
      default:
        return undefined;
    }
  }, [conferenceErrors, t]);

  const videoConstraints = {
    width: VIDEO_AVATAR_CIRCLE_SIZE.LARGE,
    height: VIDEO_AVATAR_CIRCLE_SIZE.LARGE,
    deviceId: selectedCamera?.deviceId,
  };

  const audioConstraints = {
    deviceId: selectedMicrophone?.deviceId,
  };

  const toggleMute = () => setMicMuted(!micMuted);

  // useEffect(() => {
  //   closePanel("aiVideoChatVideo");

  //   return () => {
  //     openPanel("aiVideoChatVideo");
  //   };
  // }, [closePanel, openPanel]);

  const initialiseDevices = useCallback(
    (mediaDevices: MediaDeviceInfo[]) => {
      const videoInputs = mediaDevices.filter(
        ({ kind }) => kind === "videoinput"
      );
      setVideoInputDevices(videoInputs);
      const defaultCamera =
        videoInputs.find((device) => device.deviceId === "default") ||
        videoInputs[0];
      setSelectedCamera(defaultCamera);

      const audioInputs = mediaDevices.filter(
        ({ kind }) => kind === "audioinput"
      );

      setAudioInputDevices(audioInputs);
      const defaultMicrophone =
        audioInputs.find((device) => device.deviceId === "default") ||
        audioInputs[0];
      setSelectedMicrophone(defaultMicrophone);

      const audioOutputs = mediaDevices.filter(
        ({ kind }) => kind === "audiooutput"
      );
      setAudioOutputDevices(audioOutputs);
      const defaultSpeakers =
        audioOutputs.find((device) => device.deviceId === "default") ||
        audioOutputs[0];
      setSelectedSpeakers(defaultSpeakers);
    },
    [
      setAudioInputDevices,
      setAudioOutputDevices,
      setSelectedCamera,
      setSelectedMicrophone,
      setSelectedSpeakers,
      setVideoInputDevices,
    ]
  );

  const getMediaDevices = useCallback(async () => {
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    initialiseDevices(mediaDevices);
  }, [initialiseDevices]);

  // Sometimes media devices takes a while to be available.
  // We get media devices when settings is initially opened
  // to ensure we have the latest devices.
  useEffect(() => {
    if (!isInitialised) getMediaDevices();
    setIsInitialised(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container ref={wrapperRef}>
      {false && (
        <>
          <Row height="140px" justify="center">
            <VideoAvatarWrapper>
              <HeaderBadge noBackground />
              <VideoWrapper>
                <Hide hide={webcamMuted} unMount>
                  <Webcam
                    audio={micMuted}
                    muted
                    mirrored
                    height={VIDEO_AVATAR_CIRCLE_SIZE.LARGE}
                    width={VIDEO_AVATAR_CIRCLE_SIZE.LARGE}
                    videoConstraints={videoConstraints}
                    audioConstraints={audioConstraints}
                    onUserMedia={() => {
                      setPermissions({
                        camera: true,
                        microphone: true,
                      });
                    }}
                    onUserMediaError={() => {
                      setPermissions({
                        camera: false,
                        microphone: false,
                      });
                    }}
                  />
                </Hide>
                <Hide
                  hide={!webcamMuted}
                  unMount
                  style={{ position: "absolute" }}
                >
                  <Icon.Bust size="60px" color={theme.colorBelow1} />
                </Hide>
              </VideoWrapper>
              <SpeakingIndicatorWrapper>
                <SpeakingWidget
                  micHidden={micMuted || !isSpeaking}
                  size={INDICATOR_SIZE.LARGE}
                  isSpeaking={!micMuted && isSpeaking}
                  isMuted={micMuted}
                  toggleMute={() => setMicMuted(!micMuted)}
                />
              </SpeakingIndicatorWrapper>
            </VideoAvatarWrapper>
          </Row>
          <Space h={5} />
        </>
      )}
      <Row gap={3} justify="center" align="flex-start">
        <ConferenceControlButton
          disabled={Boolean(microphoneErrorText)}
          active={!micMuted}
          error={Boolean(microphoneErrorText)}
          errorText={microphoneErrorText}
          onClick={toggleMute}
          onText={t("settings_on")}
          offText={t("settings_off")}
          label={t("settings_voice")}
          onIcon={<Icon.MicrophoneOnThin inheritColor size="20px" />}
          offIcon={<Icon.MicrophoneOffThin inheritColor size="20px" />}
        />
        <ConferenceControlButton
          disabled={cameraDisabled}
          active={!webcamMuted}
          error={Boolean(cameraErrorText)}
          errorText={cameraErrorText}
          onClick={() => setWebcamMuted(!webcamMuted)}
          onText={t("settings_on")}
          offText={t("settings_off")}
          label={t("settings_video")}
          onIcon={<Icon.VideoOn inheritColor size="20px" />}
          offIcon={<Icon.VideoOff inheritColor size="20px" />}
        />
      </Row>
      <Space h={5} />
      <DeviceSelector
        selectedAudioInputDevice={selectedMicrophone}
        selectedAudioOutputDevice={selectedSpeakers}
        selectedVideoInputDevice={selectedCamera}
        selectAudioInputDevice={setSelectedMicrophone}
        selectAudioOutputDevice={setSelectedSpeakers}
        selectVideoInputDevice={setSelectedCamera}
        audioInputDevices={audioInputDevices.filter((device) =>
          Boolean(device.deviceId)
        )}
        audioOutputDevices={audioOutputDevices.filter((device) =>
          Boolean(device.deviceId)
        )}
        videoInputDevices={videoInputDevices}
        micMuted={micMuted}
        audioStream={audioStream}
        conferenceErrors={conferenceErrors}
      />
    </Container>
  );
};

export default AiVideoChatSettingsPanel;
