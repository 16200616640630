import Row from "../../../../componentsLibrary/atoms/Row";
import { Props } from "./AiVideoChatActionBar.ui";
import AiVideoChatActionBarButtons from "./AiVideoChatActionBarButtons";

const AiVideoChatActionBarUiSmall: React.FC<Props> = ({
  onToggleSettings,
  onEndCall,
}) => {
  return (
    <Row justify="center">
      <AiVideoChatActionBarButtons
        onToggleSettings={onToggleSettings}
        onEndCall={onEndCall}
      />
    </Row>
  );
};

export default AiVideoChatActionBarUiSmall;
