import React from "react";
import { SvgProps } from "../../types/svg";

const TelephoneIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.9765 14.0933L17.2499 17.2432C17.1478 17.6885 16.7564 18 16.2979 18C8.41311 17.9994 2 11.5872 2 3.70322C2 3.24512 2.31156 2.85327 2.75695 2.75171L5.90724 2.02518C6.36604 1.91888 6.83483 2.15699 7.02548 2.5914L8.47936 5.98186C8.64932 6.38121 8.53468 6.8465 8.19871 7.12086L6.51605 8.47173C7.57803 10.6347 9.33694 12.3934 11.5009 13.4559L12.8785 11.7747C13.1501 11.4378 13.6189 11.3213 14.0183 11.4942L17.4093 12.9476C17.8171 13.1621 18.0828 13.6371 17.9765 14.0933Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default TelephoneIcon;
