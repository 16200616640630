import { ReactNode, useCallback, useState } from "react";
import AiVideoChatSettingsPanel from "../../__labs/aiVideoChat/panels/AiVideoChatSettingsPanel";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { useStore } from "../../app/store";
import { PanelTestIds } from "../../common/constants/testIds.constant";
import useFullscreenMode from "../../common/hooks/useFullscreenMode";
import { IconName } from "../../componentsLibrary/atoms/Icon";
import { SettingsPanelSubPageName } from "../_layout/panels.types";
import { useText } from "../language/language.hook";
import i18nKeys from "../language/language.types";
import { useVideoConferenceControlsContext } from "../videoConference/providers/VideoConference.provider";
import SettingsUi from "./Settings.ui";
import VolumeFullScreenTogglesNormal from "./component/VolumeAndFullscreenToggles.normal";
import useDisabledSettings from "./hooks/useDisabledSettings";
import useHiddenSettings from "./hooks/useHiddenSettings";
import useInactiveSettings from "./hooks/useInactiveSettings";
import SettingsAboutPageUi from "./subpages/SettingsAboutPage.ui";
import SettingsControlsPageUi from "./subpages/SettingsControlsPage.ui";
import SettingsWalletConnectPageUi from "./subpages/SettingsWalletConnectPage.ui";
import SettingsHomePageUi, {
  SettingName,
} from "./subpages/home/SettingsHomePage.ui";
import SettingsVideoPageLogic from "./subpages/settingsVideo/SettingsVideoPage.logic";
import SettingsStreamDiffusionPageUi from "./subpages/streamDiffusion/SettingsStreamDiffusionPage.ui";

// TODO Delete this when keys are refactored from slashes to low dashes.
const TRANSLATION_KEY_MAPS: Record<string, keyof typeof i18nKeys> = {
  "settings/streamDiffusion": "settings_stream_diffusion",
  "settings/about": "settings_about",
  "settings/controls": "settings_controls",
  "settings/home": "settings_settings",
  "settings/video": "settings_video_chat",
  "settings/walletconnect": "settings_wallet_connect",
  "settings/aiVideoChat": "settings_ai_video_chat",
};

export const settingsOptions: Setting[] = [
  {
    name: "profile",
    label: "settings_avatar",
    icon: "Bust",
    iconSize: "34px",
    testId: PanelTestIds.settings.common.profilePanel,
  },
  {
    name: "video",
    label: "settings_video_chat",
    icon: "VideoOnThin",
    iconSize: "28px",
    testId: PanelTestIds.settings.common.videoConference,
  },
  {
    name: "walletConnect",
    label: "settings_connect_wallet",
    icon: "Wallet",
    iconSize: "34px",
    testId: undefined,
  },
  {
    name: "controls",
    label: "settings_controls",
    icon: "Controller",
    iconSize: "44px",
    testId: PanelTestIds.settings.common.gameControls,
  },
  {
    name: "streamDiffusion",
    label: "settings_stream_diffusion",
    icon: "SquareInfo",
    iconSize: "34px",
    testId: undefined,
  },
  {
    name: "about",
    label: "settings_about",
    icon: "SquareInfo",
    iconSize: "34px",
    testId: undefined,
  },
];

export type Setting = {
  name: SettingName;
  label: keyof typeof i18nKeys;
  labelToggled?: keyof typeof i18nKeys;
  icon: IconName;
  iconSize?: string;
  testId?: string;
  iconInactive?: IconName;
};

type Props = {
  /** The currently open page. By default it opens the home page. */
  subpage?: SettingsPanelSubPageName;
};

const SettingsPanelLogic: React.FC<Props> = ({ subpage }) => {
  const t = useText();
  const { environment } = useEnvironmentContext();
  const { toggleLocalAudio } = useVideoConferenceControlsContext();
  const { hidden } = useHiddenSettings(environment);
  const { disabled } = useDisabledSettings();
  const { inactive } = useInactiveSettings();
  const { isFullscreen } = useStore((s) => s.ui.fullscreen);
  const { toggleFullscreen } = useFullscreenMode();
  const closePanel = useStore((s) => s.layout.closePanel);
  const openPanel = useStore((s) => s.layout.openPanel);
  const muted = useStore((s) => s.userMedia.muted);
  const selectedLocaleCode = useStore((s) => s.i18n.selectedLocaleCode);
  const setMuted = useStore((s) => s.userMedia.setAudioMuted);
  const [previousPageId, setPreviousPageId] =
    useState<SettingsPanelSubPageName>("settings/home");
  const openLanguagePanel = useCallback(() => {
    openPanel("language");
  }, [openPanel]);
  const openReportPanel = useCallback(() => {
    openPanel("report");
  }, [openPanel]);
  const onPrevious = useCallback(() => {
    openPanel(previousPageId);
  }, [openPanel, previousPageId]);
  const hidePrevious =
    !subpage ||
    subpage === "settings/home" ||
    subpage === "settings/aiVideoChat";

  const handleClose = () => {
    // Reset to home page on close.
    closePanel("settings/home");
  };

  const handleShowStats = () => {
    closePanel("settings");
    openPanel("stats");
  };

  const handlePageChange = (page: SettingsPanelSubPageName) => {
    setPreviousPageId(page);
    openPanel(page);
  };

  const handleSettingClick = (page: SettingName) => {
    switch (page) {
      case "about":
        handlePageChange("settings/about");
        break;
      case "streamDiffusion":
        handlePageChange("settings/streamDiffusion");
        break;
      case "controls":
        handlePageChange("settings/controls");
        break;
      case "video":
        handlePageChange("settings/video");
        break;
      case "profile":
        openPanel("profile");
        closePanel("settings");
        break;
      case "volume":
        setMuted(!muted);
        break;
      case "mic":
        toggleLocalAudio();
        break;
      case "fullscreen":
        toggleFullscreen();
        break;
      case "walletConnect":
        handlePageChange("settings/walletconnect");
        break;
      case "aiVideoChat":
        handlePageChange("settings/aiVideoChat");
        break;
      default:
        break;
    }
  };

  let content: ReactNode;
  let bottomBarChildren: ReactNode;
  switch (subpage) {
    case "settings/about":
      content = <SettingsAboutPageUi description={environment.description} />;
      break;
    case "settings/streamDiffusion":
      content = <SettingsStreamDiffusionPageUi />;
      break;
    case "settings/controls":
      content = <SettingsControlsPageUi />;
      break;
    case "settings/video":
      content = <SettingsVideoPageLogic onGoBack={onPrevious} />;
      break;
    case "settings/walletconnect":
      content = <SettingsWalletConnectPageUi onClose={handleClose} />;
      break;
    case "settings/aiVideoChat":
      content = <AiVideoChatSettingsPanel />;
      break;
    case "settings/home":
    default: {
      bottomBarChildren = (
        <VolumeFullScreenTogglesNormal
          isMuted={muted}
          isFullscreen={isFullscreen}
          handleSettingClick={handleSettingClick}
        />
      );

      content = (
        <SettingsHomePageUi
          disabled={disabled}
          inactive={inactive}
          hidden={hidden}
          onClick={handleSettingClick}
        />
      );
      break;
    }
  }

  return (
    <SettingsUi
      isMuted={muted}
      selectedLocaleCode={selectedLocaleCode}
      openLanguagePanel={openLanguagePanel}
      openReportPanel={openReportPanel}
      locales={environment.locales}
      title={t(TRANSLATION_KEY_MAPS[subpage ?? "settings/home"])}
      onClose={handleClose}
      subpage={subpage}
      handleSettingClick={handleSettingClick}
      bottomBarChildren={bottomBarChildren}
      onPrevious={hidePrevious ? undefined : onPrevious}
      links={environment.onboardingLinks}
      onShowStats={subpage === "settings/about" ? handleShowStats : undefined}
    >
      {content}
    </SettingsUi>
  );
};

export default SettingsPanelLogic;
