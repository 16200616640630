import { useTheme } from "styled-components";
import { useIsLandscape, useIsSmallScreen } from "../../../common/hooks/ui";
import Icon from "../../../componentsLibrary/atoms/Icon";
import Row from "../../../componentsLibrary/atoms/Row";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";
import { useText } from "../../../domains/language/language.hook";

export type PropsLoginPageUi = {
  description?: string;
  isWarning?: boolean;
};

const AiVideoChatLoginDescription: React.FC<PropsLoginPageUi> = ({
  description,
  isWarning,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const isLandscape = useIsLandscape();
  const isSmallLandscape = isSmallScreen && isLandscape;

  const theme = useTheme();
  const t = useText();

  return (
    description &&
    !isWarning &&
    !isSmallLandscape && (
      <>
        <Row justify="center">
          <Icon.SoundWave color={theme.colorBelow0} size="16px" />
          <Typo.Label color={theme.colorBelow0}>
            {t("ai_video_chat_use_camera_and_microphone")}
          </Typo.Label>
        </Row>
        <Space h={4} />
      </>
    )
  );
};

export default AiVideoChatLoginDescription;
