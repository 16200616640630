import { useEffect, useState } from "react";

const Ellipsis: React.FC = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => (prevCount + 1) % 4);
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return ".".repeat(count);
};

export default Ellipsis;
