export const VIDEO_SIZE = {
  SMALL: {
    WIDTH: 90,
    HEIGHT: 160,
  },
  LARGE: {
    WIDTH: 320,
    HEIGHT: 180,
  },
};
