import { useCallback, useEffect, useState } from "react";
import { useWebRtcContext } from "../../../../app/gameConnection/webrtc/WebRtc.provider";
import { useStore } from "../../../../app/store";
import { isAndroid } from "../../../../common/constants/flags.constant";
import { useIsLandscape, useIsSmallScreen } from "../../../../common/hooks/ui";
import { logError } from "../../../../common/util/logger";
import { useAiVideoChatContext } from "../../AiVideoChat.provider";
import { useIsSpeaking } from "../../hooks/useIsSpeaking";
import { VIDEO_SIZE } from "../../lib/constants";
import AiVideoChatVideoUi from "./AiVideoChatVideo.ui";

const AiVideoChatVideoLogic: React.FC<{ hide: boolean }> = ({ hide }) => {
  const {
    setPermissions,
    setVideoInputDevices,
    setAudioInputDevices,
    setAudioOutputDevices,
    selectedCamera,
    selectedMicrophone,
    setSelectedCamera,
    setSelectedMicrophone,
    setSelectedSpeakers,
    micMuted,
    webcamMuted,
    setWebcamMuted,
  } = useStore((s) => s.userMedia);
  const isSmallScreen = useIsSmallScreen();
  const isLandscape = useIsLandscape();
  const webRtcContext = useWebRtcContext();
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
  const isSpeaking = useIsSpeaking(audioStream);
  const { isInitialised } = useAiVideoChatContext();

  const initialiseDevices = useCallback(
    (mediaDevices: MediaDeviceInfo[]) => {
      const videoInputs = mediaDevices.filter(
        ({ kind }) => kind === "videoinput"
      );
      setVideoInputDevices(videoInputs);
      const defaultCamera =
        videoInputs.find((device) => device.deviceId === "default") ||
        videoInputs[0];
      setSelectedCamera(defaultCamera);

      const audioInputs = mediaDevices.filter(
        ({ kind }) => kind === "audioinput"
      );

      setAudioInputDevices(audioInputs);
      const defaultMicrophone =
        audioInputs.find((device) => device.deviceId === "default") ||
        audioInputs[0];
      setSelectedMicrophone(defaultMicrophone);

      const audioOutputs = mediaDevices.filter(
        ({ kind }) => kind === "audiooutput"
      );
      setAudioOutputDevices(audioOutputs);
      const defaultSpeakers =
        audioOutputs.find((device) => device.deviceId === "default") ||
        audioOutputs[0];
      setSelectedSpeakers(defaultSpeakers);
    },
    [
      setAudioInputDevices,
      setAudioOutputDevices,
      setSelectedCamera,
      setSelectedMicrophone,
      setSelectedSpeakers,
      setVideoInputDevices,
    ]
  );

  useEffect(() => {
    const requestPermissions = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });

        if (stream) {
          const audioTracks = stream.getAudioTracks();
          if (audioTracks.length > 0) {
            const audioStream = new MediaStream(audioTracks);
            setAudioStream(audioStream);
            webRtcContext.addAudioStream?.(audioStream);
          } else {
            logError(
              "VOICE/VIDEO",
              "AI Video Chat - No audio track available."
            );
          }
        }

        setPermissions({
          camera: true,
          microphone: true,
        });
      } catch (err) {
        // If getting both fails, try audio only
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          });

          if (stream) {
            const audioTracks = stream.getAudioTracks();
            if (audioTracks.length > 0) {
              const audioStream = new MediaStream(audioTracks);
              setAudioStream(audioStream);
              webRtcContext.addAudioStream?.(audioStream);
            } else {
              logError(
                "VOICE/VIDEO",
                "AI Video Chat - No audio track available."
              );
            }
          }

          setPermissions({
            camera: false,
            microphone: true,
          });
        } catch (audioErr) {
          logError(
            "VOICE/VIDEO",
            "AI Video Chat - No audio devices available: " + audioErr
          );
          // If audio fails, try video only
          try {
            await navigator.mediaDevices.getUserMedia({
              audio: false,
              video: true,
            });
            setPermissions({
              camera: true,
              microphone: false,
            });
          } catch (videoErr) {
            logError(
              "VOICE/VIDEO",
              "AI Video Chat - No video devices available: " + videoErr
            );
          }
        }
      }
    };

    requestPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Mute/unmute microphone
  useEffect(() => {
    if (isInitialised) {
      audioStream?.getAudioTracks().forEach((track) => {
        track.enabled = !micMuted;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [micMuted]);

  // Update audio stream when selected microphone changes
  useEffect(() => {
    const getNewAudioStream = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: selectedMicrophone?.deviceId } },
      });

      setAudioStream(stream);
      webRtcContext?.replaceAudioStream?.(stream);
    };

    if (isInitialised && selectedMicrophone) {
      getNewAudioStream();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMicrophone]);

  // Update devices when device list changes
  useEffect(() => {
    const handleDeviceChange = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      initialiseDevices(devices);
    };

    if (isAndroid) {
      navigator.mediaDevices.ondevicechange = handleDeviceChange;
    } else {
      navigator.mediaDevices.addEventListener(
        "devicechange",
        handleDeviceChange
      );
    }
    return () => {
      if (isAndroid) {
        navigator.mediaDevices.ondevicechange = null;
      } else {
        navigator.mediaDevices.removeEventListener(
          "devicechange",
          handleDeviceChange
        );
      }
    };
  }, [initialiseDevices]);

  /**
   * The default orientation for desktop is landscape while mobile is portrait.
   * Swapping the width and height constraints properly sets the size on mobile.
   * https://github.com/mozmorris/react-webcam/issues/370#issuecomment-2118663884
   * */
  const videoConstraints = {
    width: isSmallScreen
      ? isLandscape
        ? VIDEO_SIZE.SMALL.WIDTH
        : VIDEO_SIZE.SMALL.HEIGHT
      : VIDEO_SIZE.LARGE.WIDTH,
    height: isSmallScreen
      ? isLandscape
        ? VIDEO_SIZE.SMALL.HEIGHT
        : VIDEO_SIZE.SMALL.WIDTH
      : VIDEO_SIZE.LARGE.HEIGHT,
    deviceId: selectedCamera?.deviceId,
  };

  const audioConstraints = {
    deviceId: selectedMicrophone?.deviceId,
  };

  return (
    <AiVideoChatVideoUi
      hide={hide}
      webcamMuted={webcamMuted}
      micMuted={micMuted}
      setWebcamMuted={setWebcamMuted}
      videoConstraints={videoConstraints}
      audioConstraints={audioConstraints}
      isSpeaking={isSpeaking}
      setPermissions={setPermissions}
    />
  );
};

export default AiVideoChatVideoLogic;
