import { useEffect, useState } from "react";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import EnvironmentDisconnectHandler from "../../app/EnvironmentDisconnectHandler.core";
import WebRtcProvider from "../../app/gameConnection/webrtc/WebRtc.provider";
import { useIdentifyLogRocketUser } from "../../app/monitoring/tracking/logrocket";
import { useStore } from "../../app/store";
import GamePlayer from "../../domains/_experience/GamePlayer";
import PanelSystem from "../../domains/_experience/PanelSystem";
import LoginPage from "../../domains/_login/LoginPage";
import FullscreenHelper from "../../domains/_login/component/SwipeUpHelper/FullscreenHelper";
import EndingPanel from "../../domains/endingPanel/Ending.logic";
import { useCopywriting } from "../../domains/language/language.hook";
import ReactionsProvider from "../../domains/presentationBar/providers/Reactions.provider";
import SocketDisconnectPageUi from "../../domains/utilityPages/SocketDisconnectPageUi";
import AiVideoChatProvider from "./AiVideoChat.provider";
import AiVideoChatBackground from "./AiVideoChatBackground";
import AiVideoChatLogic from "./AiVideoChatLogic";

const AiVideoChatRoute: React.FC = () => {
  const completeStep = useStore((s) => s.userFlow.completeStep);
  const registerCleanupHook = useStore((s) => s.userFlow.registerCleanupHook);
  const { environment } = useEnvironmentContext();

  const [mountLogin, setMountLogin] = useState(true);

  useEffect(() => {
    registerCleanupHook("removeLoginPage", () => setMountLogin(false));
  }, [registerCleanupHook]);
  useIdentifyLogRocketUser();
  useCopywriting();

  useEffect(() => {
    if (environment) {
      completeStep("load-environment");
    }
  }, [environment, completeStep]);

  return (
    <EnvironmentDisconnectHandler displayPage={SocketDisconnectPageUi}>
      <WebRtcProvider>
        <AiVideoChatBackground />
        <EndingPanel />
        <ReactionsProvider>
          <AiVideoChatProvider>
            <PanelSystem />
          </AiVideoChatProvider>
        </ReactionsProvider>
        <FullscreenHelper>
          <GamePlayer />
          <AiVideoChatLogic />
        </FullscreenHelper>
        {mountLogin && <LoginPage onDone={() => setMountLogin(false)} />}
      </WebRtcProvider>
    </EnvironmentDisconnectHandler>
  );
};

export default AiVideoChatRoute;
