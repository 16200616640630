import { styled } from "styled-components";
import { useStore } from "../../../../app/store";
import Hide from "../../../../componentsLibrary/atoms/Hide";
import useIsAiVideoChat from "../../hooks/useIsAiVideoChat";
import TextChatPreviewLogic from "./AiVideoChatActionBar.logic";

const Layout = styled.div`
  right: 0px;
  position: absolute;
  right: 32px;
  top: 32px;
  left: 32px;
`;

const AiVideoChatActionBarPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.aiVideoChatActionBar.visible);
  const isAiVideoChat = useIsAiVideoChat();

  return (
    isAiVideoChat && (
      <Layout id="text-chat-preview-panel">
        <Hide hide={!visible}>
          <TextChatPreviewLogic />
        </Hide>
      </Layout>
    )
  );
};

export default AiVideoChatActionBarPanel;
