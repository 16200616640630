import { useTheme } from "styled-components";
import Button from "../../../../componentsLibrary/atoms/Button";
import Icon from "../../../../componentsLibrary/atoms/Icon";
import Row from "../../../../componentsLibrary/atoms/Row";
import { useText } from "../../../../domains/language/language.hook";
import { Props } from "./AiVideoChatActionBar.ui";

const AiVideoChatActionBarButtons: React.FC<Props> = ({
  onToggleSettings,
  onEndCall,
}) => {
  const theme = useTheme();
  const t = useText();

  return (
    <Row gap={2} justify="flex-end" width="fit-content">
      <Button.Glass circular onClick={onToggleSettings}>
        <Icon.Gear size="20px " />
      </Button.Glass>
      <Button.Glass
        override={{
          height: "32px",
          background: theme.colorDanger,
          hoverBackground: theme.colorDanger,
          color: theme.colorBelow1,
          hoverColor: theme.colorBelow1,
        }}
        iconLeft={
          <Icon.TelephoneHangup color={theme.colorBelow1} shiftY="1px" />
        }
        onClick={onEndCall}
      >
        {t("ai_video_chat_end_call")}
      </Button.Glass>
    </Row>
  );
};

export default AiVideoChatActionBarButtons;
