import { useStore } from "../../../../app/store";
import useIsAiVideoChat from "../../hooks/useIsAiVideoChat";
import AiVideoChatVideoLogic from "./AiVideoChatVideo.logic";

const AiVideoChatVideoPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.aiVideoChatVideo.visible);
  const isAiVideoChat = useIsAiVideoChat();

  return isAiVideoChat && <AiVideoChatVideoLogic hide={!visible} />;
};

export default AiVideoChatVideoPanel;
