import { ReactNode, createContext, useContext, useState } from "react";

export type AiVideoChatContextType = {
  isInitialised: boolean;
  setIsInitialised: (value: boolean) => void;
};

const AiVideoChatContext = createContext<AiVideoChatContextType | undefined>(
  undefined
);

export const AiVideoChatContextProvider = AiVideoChatContext.Provider;

export const useAiVideoChatContext = () => {
  const context = useContext(AiVideoChatContext);
  if (context === undefined) {
    throw new Error(
      "AiVideoChatContext must be within the AI Video Chat Route"
    );
  }
  return context;
};

type Props = {
  children?: ReactNode;
};

const AiVideoChatProvider: React.FC<Props> = ({ children }) => {
  const [isInitialised, setIsInitialised] = useState(false);

  return (
    <AiVideoChatContextProvider
      value={{
        isInitialised,
        setIsInitialised,
      }}
    >
      {children}
    </AiVideoChatContextProvider>
  );
};

export default AiVideoChatProvider;
