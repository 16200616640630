import { useTheme } from "styled-components";
import Row from "../../../../componentsLibrary/atoms/Row";
import Typo from "../../../../componentsLibrary/atoms/Typo";
import { useText } from "../../../../domains/language/language.hook";
import { Props } from "./AiVideoChatActionBar.ui";
import AiVideoChatActionBarButtons from "./AiVideoChatActionBarButtons";

const AiVideoChatActionBarUiNormal: React.FC<Props> = ({
  onToggleSettings,
  onEndCall,
  name,
}) => {
  const theme = useTheme();
  const t = useText();

  return (
    <Row justify="space-between">
      {name && (
        <Typo.Body size="24px" color={theme.colorBelow2}>
          {t("ai_video_chat_call_with", { name })}
        </Typo.Body>
      )}
      <AiVideoChatActionBarButtons
        onToggleSettings={onToggleSettings}
        onEndCall={onEndCall}
      />
    </Row>
  );
};

export default AiVideoChatActionBarUiNormal;
