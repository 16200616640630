import { useCallback, useEffect, useRef, useState } from "react";
import { MediaCaptureService } from "../lib/MediaCapture.service";

export function useVideoCapture(
  video: MediaStream | undefined,
  audio: MediaStream | undefined,
  onRecorded?: (blob: Blob) => void
) {
  const hasCheckedSupport = useRef<boolean>(false);
  const [videoCodec, setVideoCodec] = useState<string | undefined | null>();
  const [audioCodec, setAudioCodec] = useState<string | undefined | null>();
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [audioChannels, setAudioChannels] = useState<number>();
  const [audioSampleRate, setAudioSampleRate] = useState<number>();
  const worker = useRef<Worker | null>(null);
  const recorder = useRef<MediaRecorder | null>(null);
  const [isIntialized, setIsIntialized] = useState(false);

  const onDataAvailableHandler = useCallback(
    (e: BlobEvent) => {
      if (onRecorded) {
        onRecorded(e.data);
      }
    },
    [onRecorded]
  );

  const onWorkerMessageHandler = useCallback(
    (e: MessageEvent) => {
      if (!onRecorded) return;
      if (e.data.type === "recorded") {
        onRecorded(e.data.blob);
      }
    },
    [onRecorded]
  );

  // Get supported encoder and codecs
  useEffect(() => {
    if (!video || !audio) return;
    if (hasCheckedSupport.current) return;
    const isEncoderSupported = MediaCaptureService.isEncoderSupported();
    if (isEncoderSupported) {
      const videoStream = video.getVideoTracks()[0];
      const videoSettings = videoStream.getSettings();
      const audioStream = audio.getAudioTracks()[0];
      const audioSettings = audioStream.getSettings();
      Promise.all([
        MediaCaptureService.getSupportedVideoCodec(
          videoSettings.width,
          videoSettings.height
        ),
        MediaCaptureService.getSupportedAudioCodec(
          audioSettings.channelCount,
          audioSettings.sampleRate
        ),
      ]).then(([videoCodec, audioCodec]) => {
        if (videoCodec && audioCodec) {
          // We have full WebCodecs API support
          setVideoCodec(videoCodec);
          setAudioCodec(audioCodec);
          setWidth(videoSettings.width);
          setHeight(videoSettings.height);
          setAudioChannels(audioSettings.channelCount);
          setAudioSampleRate(audioSettings.sampleRate);
        } else {
          // We have MediaRecorder API support
          setVideoCodec(null);
          setAudioCodec(null);
        }
        hasCheckedSupport.current = true;
      });
    } else {
      hasCheckedSupport.current = true;
    }
  }, [video, audio, hasCheckedSupport]);

  // Deploy workers
  useEffect(() => {
    if (!hasCheckedSupport.current) return;
    if (!video || !audio) return;
    if (worker.current) return;
    const isMp4Supported = MediaCaptureService.isMp4Supported();
    const hasCodecsSupport = videoCodec && audioCodec;

    if (hasCodecsSupport && !isMp4Supported) {
      // WebCodecs API is supported
      worker.current = new Worker(
        new URL("../lib/VideoEncoder.worker.ts", import.meta.url),
        {
          type: "module",
        }
      );
      worker.current?.addEventListener("message", onWorkerMessageHandler);
      worker.current.postMessage({
        type: "settings",
        data: {
          videoCodec,
          audioCodec,
          width,
          height,
          audioChannels,
          audioSampleRate,
        },
      });
    } else {
      // MediaRecorder API is supported
      const stream = new MediaStream([
        video.getVideoTracks()[0],
        audio.getAudioTracks()[0],
      ]);
      recorder.current = new MediaRecorder(stream, {
        mimeType: isMp4Supported ? "video/mp4" : "video/webm",
      });
      recorder.current.addEventListener(
        "dataavailable",
        onDataAvailableHandler
      );
    }
    setIsIntialized(true);

    return () => {
      if (worker.current) {
        worker.current.removeEventListener("message", onWorkerMessageHandler);
        worker.current.terminate();
        worker.current = null;
      }
      if (recorder.current) {
        recorder.current.removeEventListener(
          "dataavailable",
          onDataAvailableHandler
        );
        recorder.current.stop();
        recorder.current = null;
      }
    };
  }, [
    audio,
    video,
    videoCodec,
    audioCodec,
    width,
    height,
    audioChannels,
    audioSampleRate,
    onWorkerMessageHandler,
    onDataAvailableHandler,
    setIsIntialized,
  ]);

  return {
    isIntialized,
    worker,
    recorder,
  };
}
