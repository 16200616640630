import React from "react";
import { SvgProps } from "../../types/svg";

const TelephoneHangupIcon: React.FC<SvgProps> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.6749 9.14488C15.6275 7.09609 12.9026 6 10.0002 6C7.09778 6 4.37037 7.11723 2.32543 9.14488C1.95904 9.507 1.89497 10.0635 2.16919 10.4962L3.42065 12.48C3.70514 12.9326 4.27637 13.1195 4.7851 12.9214L7.29252 11.9279C7.74726 11.7464 8.03075 11.2831 7.9815 10.8031L7.85525 9.52644C9.25046 9.11863 10.7527 9.11814 12.1501 9.52682L12.0239 10.7799C11.9716 11.2638 12.2564 11.7277 12.7154 11.9078L15.2203 12.8997C15.3563 12.9536 15.4968 12.9794 15.6358 12.9794C16.0116 12.9794 16.3733 12.7911 16.5816 12.4608L17.8324 10.4962C18.1049 10.0635 18.0399 9.507 17.6749 9.14488ZM17.1275 10.0903L15.8748 12.0764C15.7947 12.204 15.6353 12.2571 15.4968 12.2017L12.9918 11.2091C12.8629 11.159 12.7801 11.0215 12.7954 10.8803L12.9532 9.31345C12.9711 9.1346 12.861 8.96712 12.6867 8.90831C11.813 8.61277 10.8952 8.46525 9.97676 8.46525C9.05954 8.46525 8.14232 8.61258 7.26935 8.90806C7.12028 8.96503 7.01028 9.13272 7.02778 9.31257L7.1848 10.8809C7.19925 11.0221 7.118 11.1582 6.98833 11.2097L4.48591 12.2008C4.34569 12.257 4.18392 12.2038 4.10342 12.0747L2.85091 10.0879C2.76967 9.96026 2.78842 9.79621 2.89621 9.68952C4.79035 7.81304 7.31277 6.77771 9.97769 6.77771C12.6426 6.77771 15.1875 7.81183 17.0825 9.68927C17.2125 9.79621 17.23 9.9396 17.1275 10.0903Z"
      fill="currentColor"
      className="fill-color"
    />
  </svg>
);
export default TelephoneHangupIcon;
