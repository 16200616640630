import { useEffect } from "react";
import { useNavigate } from "react-router";
import { styled, useTheme } from "styled-components";
import { useCurrentLoginBackgroundImage } from "../../../app/hooks/gyarados.hook";
import { useGetAiVideoChatPath } from "../../../app/hooks/routing.hook";
import { useStore } from "../../../app/store";
import { grayscaleInvert } from "../../../common/util/color";
import { getImageUrl } from "../../../common/util/gyarados.util";
import Button from "../../../componentsLibrary/atoms/Button";
import Column from "../../../componentsLibrary/atoms/Column";
import HeaderBadge from "../../../componentsLibrary/atoms/HeaderBadge";
import Icon from "../../../componentsLibrary/atoms/Icon";
import Space from "../../../componentsLibrary/atoms/Space";
import Typo from "../../../componentsLibrary/atoms/Typo";
import PanelSystem from "../../../domains/_experience/PanelSystem";
import { Vignette } from "../../../domains/_login/LoginPageUi";
import { useText } from "../../../domains/language/language.hook";

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  background-color: black;
  position: absolute;
  z-index: -1;
`;

const Wrapper = styled.div`
  width: min(600px, 100%);
`;

const AiVideoChatEndPageUi: React.FC = () => {
  const navigate = useNavigate();
  const aiVideoChatPath = useGetAiVideoChatPath();
  const t = useText();
  const openPanel = useStore((s) => s.layout.openPanel);
  const backgroundImageSource = useCurrentLoginBackgroundImage();
  const backgroundImageUrl = getImageUrl(backgroundImageSource);
  const theme = useTheme();

  const inverted = theme.isLoginColorInverted;
  const colorBelowRoot = inverted
    ? grayscaleInvert(theme.colorBelowRoot)
    : theme.colorBelowRoot;

  useEffect(() => {
    openPanel("poll", {
      slug: "end",
    });
  }, [openPanel]);

  return (
    <>
      <PanelSystem />
      <Img src={backgroundImageUrl} />
      <Vignette $color={colorBelowRoot} />
      <Column
        textAlign="center"
        justify="center"
        align="center"
        gap={4}
        width="100dvw"
        height="100dvh"
      >
        <HeaderBadge rippleLineWidth={5} noBackground color="inverted">
          <Icon.Bust inheritColor size="40px" />
        </HeaderBadge>
        <Typo.Title color="white">{t("end_session_ended")}</Typo.Title>
        <Wrapper>
          <Typo.Body color="white" size="17px">
            {t("end_session_description")}
          </Typo.Body>
        </Wrapper>
        <Space h={2} />
        <Button.Bright
          onClick={() => {
            navigate(aiVideoChatPath, {
              relative: "path",
            });
            navigate(0); // refresh
          }}
        >
          {t("end_session_back_to_start")}
        </Button.Bright>
      </Column>
    </>
  );
};

export default AiVideoChatEndPageUi;
