import { useCallback } from "react";
import useGetAiVideoChatModel from "../../__labs/aiVideoChat/hooks/useGetAiVideoChatModel";
import useIsAiVideoChat from "../../__labs/aiVideoChat/hooks/useIsAiVideoChat";
import AiVideoChatPollUi from "../../__labs/aiVideoChat/panels/aiVideoChatPoll/AiVideoChatPoll.ui";
import { useGetPollBySlug } from "../../app/hooks/gyarados.hook";
import { useStore } from "../../app/store";
import { PanelTestIds } from "../../common/constants/testIds.constant";
import PollUi from "./Poll.ui";

type Props = {
  slug?: string;
};

const PollLogic: React.FC<Props> = ({ slug }) => {
  const closePanel = useStore((s) => s.layout.closePanel);
  const poll = useGetPollBySlug(slug);
  const visitorToken = useStore((s) => s.session.visitorToken);
  const visitorTokenData = useStore((s) => s.session.visitorTokenData);
  const roomId = useStore((s) => s.gameConnection.roomId);
  const playerId = useStore((s) => s.gameConnection.playerId);
  const clientPersistedId = useStore((s) => s.session.clientPersistedId);
  const isAiVideoChat = useIsAiVideoChat();
  const aiVideoChatModel = useGetAiVideoChatModel();

  const handleDismiss = useCallback(() => {
    closePanel("poll", { slug });
  }, [closePanel, slug]);

  return isAiVideoChat ? (
    <AiVideoChatPollUi
      visitorToken={visitorToken}
      visitorTokenData={visitorTokenData}
      roomId={roomId}
      playerId={playerId}
      clientPersistedId={clientPersistedId}
      poll={poll}
      handleDismiss={handleDismiss}
      testId={PanelTestIds.poll.root}
      aiVideoChatModel={aiVideoChatModel}
    />
  ) : (
    <PollUi
      visitorToken={visitorToken}
      visitorTokenData={visitorTokenData}
      roomId={roomId}
      playerId={playerId}
      clientPersistedId={clientPersistedId}
      poll={poll}
      handleDismiss={handleDismiss}
      testId={PanelTestIds.poll.root}
    />
  );
};

export default PollLogic;
