import { ReactNode, createContext, useContext, useState } from "react";

type MediaStreamFunction = (stream: MediaStream) => void;

export type WebRtcContextType = {
  addAudioStream: MediaStreamFunction | undefined;
  setAddAudioStream: (value: MediaStreamFunction) => void;
  removeAudioStream: MediaStreamFunction | undefined;
  setRemoveAudioStream: (value: MediaStreamFunction) => void;
  replaceAudioStream: MediaStreamFunction | undefined;
  setReplaceAudioStream: (value: MediaStreamFunction) => void;
};

const WebRtcContext = createContext<WebRtcContextType | undefined>(undefined);

export const WebRtcContextProvider = WebRtcContext.Provider;

export const useWebRtcContext = () => {
  const context = useContext(WebRtcContext);
  if (context === undefined) {
    throw new Error("WebRtcContext must be within the Experience Route");
  }
  return context;
};

type Props = {
  children?: ReactNode;
};

const WebRtcProvider: React.FC<Props> = ({ children }) => {
  const [addAudioStream, setAddAudioStream] = useState<
    MediaStreamFunction | undefined
  >();
  const [removeAudioStream, setRemoveAudioStream] = useState<
    MediaStreamFunction | undefined
  >();
  const [replaceAudioStream, setReplaceAudioStream] = useState<
    MediaStreamFunction | undefined
  >();

  return (
    <WebRtcContextProvider
      value={{
        addAudioStream,
        setAddAudioStream,
        removeAudioStream,
        setRemoveAudioStream,
        replaceAudioStream,
        setReplaceAudioStream,
      }}
    >
      {children}
    </WebRtcContextProvider>
  );
};

export default WebRtcProvider;
