import styled, { useTheme } from "styled-components";
import { zIndex } from "../../app/style/theme";
import Hide from "../../componentsLibrary/atoms/Hide";
import Icon from "../../componentsLibrary/atoms/Icon";
import { Props } from "./AiAvatar.ui";

const Container = styled.div`
  top: 24px;
  left: max(var(--safe-margin-right), 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

const AiAvatarUiNormal: React.FC<Props> = ({
  visible,
  size = 160,
  videoRef,
  isReady,
}) => {
  const theme = useTheme();

  return (
    <Container>
      <Hide
        hide={!visible}
        height={`${size}px`}
        width={`${size}px`}
        style={{
          borderRadius: "100%",
          overflow: "hidden",
          background: theme.colorBelow0,
          backdropFilter: `blur(${theme.blurStrong})`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: zIndex.overlays,
          pointerEvents: "all",
          border: "solid 3px white",
        }}
      >
        <Hide
          hide={isReady}
          unMount
          height={`${size}px`}
          width={`${size}px`}
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon.SpinningAnimated />
        </Hide>
        <video
          id="avatarVideo"
          autoPlay
          playsInline
          width={`${size}px`}
          height={`${size}px`}
          ref={videoRef}
          style={{ objectFit: "cover" }}
        />
      </Hide>
    </Container>
  );
};

export default AiAvatarUiNormal;
