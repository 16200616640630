import { memo, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import {
  isFirefox,
  isSafari,
} from "../../../../common/constants/flags.constant";
import { ConferenceError } from "../../../../common/hooks/useConferenceErrors";
import Hide from "../../../../componentsLibrary/atoms/Hide";
import Icon from "../../../../componentsLibrary/atoms/Icon";
import Space from "../../../../componentsLibrary/atoms/Space";
import Typo from "../../../../componentsLibrary/atoms/Typo";
import DropdownSelect from "../../../../componentsLibrary/molecules/DropdownSelect";
import { useText } from "../../../language/language.hook";
import SimpleAudioMeter from "./util/audioMeter";

const Message = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 0;
  min-width: 0;
  text-wrap: wrap;
  width: auto;
  padding: 12px 16px;
`;

const MicWrappper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const MicrophoneBar = styled.div`
  background: ${(props) => props.theme.colorGradient.purpleBlue.value};
  backdrop-filter: blur(98px);
  height: 2px;
  transform-origin: left;
`;

const Container = styled.div<{ $disabled?: boolean }>`
  position: relative;
  display: flex;
  min-width: 0;

  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${({ $disabled }) =>
    $disabled &&
    ` 
      pointer-events: none;
      opacity: 0.5;
    `}
`;

interface Props {
  disabled?: boolean;
  conferenceErrors: ConferenceError[];
  audioStream: MediaStream | null;
  micMuted: boolean;
  selectedAudioInputDevice: MediaDeviceInfo | null;
  selectedAudioOutputDevice: MediaDeviceInfo | null;
  selectedVideoInputDevice: MediaDeviceInfo | null;
  selectAudioInputDevice: (device: MediaDeviceInfo) => void;
  selectAudioOutputDevice: (device: MediaDeviceInfo) => void;
  selectVideoInputDevice: (device: MediaDeviceInfo) => void;
  audioInputDevices: MediaDeviceInfo[];
  audioOutputDevices: MediaDeviceInfo[];
  videoInputDevices: MediaDeviceInfo[];
}

const DeviceSelector: React.FC<Props> = ({
  disabled,
  conferenceErrors,
  audioStream,
  micMuted,
  selectedAudioInputDevice,
  selectedAudioOutputDevice,
  selectedVideoInputDevice,
  selectAudioInputDevice,
  selectAudioOutputDevice,
  selectVideoInputDevice,
  audioInputDevices,
  audioOutputDevices,
  videoInputDevices,
}) => {
  const t = useText();

  const audioMeterRef = useRef<SimpleAudioMeter | null>(null);
  const volumeBarRef = useRef<HTMLDivElement>(null);
  const [mutedMessageVisible, setMutedMessageVisible] = useState(false);

  // Because we are requestAnimationFrame (audiometer), we have to do these refs
  const micMutedRef = useRef(micMuted);
  micMutedRef.current = micMuted;

  const messageVisibleRef = useRef(mutedMessageVisible);
  messageVisibleRef.current = mutedMessageVisible;

  useEffect(() => {
    if (!audioStream || audioMeterRef?.current) return;
    const audioMeter = new SimpleAudioMeter(audioStream);
    audioMeterRef.current = audioMeter;
    audioMeter.start((volume) => {
      if (volumeBarRef.current) {
        volumeBarRef.current.style.transform = `scaleX(${volume})`;
      }
      if (volume > 0.1 && !messageVisibleRef.current && micMutedRef.current) {
        setMutedMessageVisible(true);
      } else if (messageVisibleRef.current && !micMutedRef.current) {
        setMutedMessageVisible(false);
      }
    });
    return () => {
      audioMeter.stop();
      audioMeterRef.current = null;
    };
  }, [audioStream]);

  useEffect(() => {
    if (audioStream && !micMuted) {
      setMutedMessageVisible(false);
    }
  }, [micMuted, audioStream]);

  const _selectMicrophone = (deviceId: string) => {
    const deviceInfo = audioInputDevices?.find((s) => s.deviceId === deviceId);
    if (deviceInfo) {
      selectAudioInputDevice(deviceInfo);
    }
  };
  const _selectSpeakers = (deviceId: string) => {
    const deviceInfo = audioOutputDevices?.find((s) => s.deviceId === deviceId);
    if (deviceInfo) {
      selectAudioOutputDevice(deviceInfo);
    }
  };

  const _selectCamera = (deviceId: string) => {
    const deviceInfo = videoInputDevices?.find((s) => s.deviceId === deviceId);
    if (deviceInfo) {
      selectVideoInputDevice(deviceInfo);
    }
  };

  const cameraErrorMessage = useMemo(() => {
    switch (true) {
      case conferenceErrors.includes("cameraAccessDenied"):
        return (
          <Message>
            <Icon.CircleInfo size="18px" />
            <Space w={3} />
            <Typo.Note>
              {t("settings_camera_permissions_denied_message")}
            </Typo.Note>
          </Message>
        );
      case conferenceErrors.includes("noCameraAvailable"):
        return (
          <Message>
            <Icon.CircleInfo size="18px" />
            <Space w={3} />
            <Typo.Note>{t("settings_camera_device_missing_message")}</Typo.Note>
          </Message>
        );
      case conferenceErrors.includes("cameraFeedError"):
        return (
          <Message>
            <Icon.CircleInfo size="18px" />
            <Space w={3} />
            <Typo.Note>{t("settings_camera_error_message")}</Typo.Note>
          </Message>
        );
      default:
        return undefined;
    }
  }, [conferenceErrors, t]);

  const microphoneErrorMessage = useMemo(() => {
    switch (true) {
      case conferenceErrors.includes("microphoneAccessDenied"):
        return (
          <Message>
            <Icon.CircleInfo size="18px" />
            <Space w={3} />
            <Typo.Note>
              {t("settings_microphone_permissions_denied_message")}
            </Typo.Note>
          </Message>
        );
      case conferenceErrors.includes("noMicrophoneAvailable"):
        return (
          <Message>
            <Icon.CircleInfo size="18px" />
            <Space w={3} />
            <Typo.Note>
              {t("settings_microphone_device_missing_message")}
            </Typo.Note>
          </Message>
        );
      default:
        return undefined;
    }
  }, [conferenceErrors, t]);

  const disableAudioOutputDeviceSelection = isFirefox || isSafari;
  const disableCameraSelected =
    conferenceErrors.includes("cameraAccessDenied") ||
    conferenceErrors.includes("noCameraAvailable");
  const unreachableSpeakersMessage =
    isFirefox || isSafari ? (
      <Message>
        <Icon.CircleInfo size="18px" />
        <Space w={3} />
        <Typo.Note>
          {t("settings_unreachable_speakers", {
            browser: isFirefox ? "Firefox" : "Safari",
          })}
        </Typo.Note>
      </Message>
    ) : null;

  const microphoneMessage = mutedMessageVisible ? (
    <Message>
      <Icon.CircleInfo size="18px" />
      <Space w={3} />
      <Typo.Note>{t("settings_mic_muted_message")}</Typo.Note>
    </Message>
  ) : null;

  return (
    <Container $disabled={disabled}>
      <MicWrappper>
        <DropdownSelect
          errorMessage={microphoneErrorMessage}
          label={t("settings_select_microphone")}
          onSelect={_selectMicrophone}
          infoMessage={microphoneMessage}
          selected={selectedAudioInputDevice?.deviceId}
          options={
            audioInputDevices?.map((mic) => ({
              value: mic.deviceId,
              label: mic.label,
            })) ?? []
          }
        />
        <Hide hide={mutedMessageVisible || micMuted}>
          <MicrophoneBar ref={volumeBarRef} />
        </Hide>
      </MicWrappper>

      <Space h={2} />
      <DropdownSelect
        disabled={disableCameraSelected}
        errorMessage={cameraErrorMessage}
        onSelect={_selectCamera}
        label={t("settings_video_input")}
        selected={selectedVideoInputDevice?.deviceId}
        options={
          videoInputDevices?.map((camera) => ({
            value: camera.deviceId,
            label: camera.label,
          })) ?? []
        }
      />
      <Space h={2} />
      <DropdownSelect
        disabled={disableAudioOutputDeviceSelection}
        onSelect={_selectSpeakers}
        infoMessage={unreachableSpeakersMessage}
        label={t("settings_audio_output")}
        selected={selectedAudioOutputDevice?.deviceId}
        options={
          audioOutputDevices?.map((speaker) => ({
            value: speaker.deviceId,
            label: speaker.label,
          })) ?? []
        }
      />
    </Container>
  );
};

export default memo(
  DeviceSelector,
  (prev, next) =>
    prev.selectedAudioInputDevice === next.selectedAudioInputDevice &&
    prev.selectedAudioOutputDevice === next.selectedAudioOutputDevice &&
    prev.selectedVideoInputDevice === next.selectedVideoInputDevice &&
    prev.audioInputDevices === next.audioInputDevices &&
    prev.audioOutputDevices === next.audioOutputDevices &&
    prev.videoInputDevices === next.videoInputDevices &&
    prev.micMuted === next.micMuted &&
    prev.audioStream === next.audioStream &&
    prev.disabled === next.disabled &&
    prev.conferenceErrors === next.conferenceErrors
);
