import { useEnvironmentContext } from "../../../../app/EnvironmentDataProvider";
import { useAiVideoChatEndedPath } from "../../../../app/hooks/routing.hook";
import { useStore } from "../../../../app/store";
import AiVideoChatActionBarUi from "./AiVideoChatActionBar.ui";

const AiVideoChatActionBarLogic: React.FC = () => {
  const isSettingsPanelVisible = useStore(
    (s) => s.layout.panels.settings.visible
  );
  const settingsPanelSubPage = useStore(
    (s) => s.layout.panels.settings.subpage
  );
  const closePanel = useStore((s) => s.layout.closePanel);
  const openPanel = useStore((s) => s.layout.openPanel);
  const endedUrl = useAiVideoChatEndedPath();
  const { environment } = useEnvironmentContext();

  // TODO: Remove this once we have a proper way to select the AI chat model
  const aiChatModel = environment.avatars.reduce(
    (max, obj) => (obj.sortIndex < max.sortIndex ? obj : max),
    environment.avatars[0]
  );

  const handleToggleSettings = () => {
    isSettingsPanelVisible && settingsPanelSubPage === "settings/aiVideoChat"
      ? closePanel("settings/aiVideoChat")
      : openPanel("settings/aiVideoChat");
  };

  const handleEndCall = () => {
    window.location.href = endedUrl;
  };

  return (
    <AiVideoChatActionBarUi
      onToggleSettings={handleToggleSettings}
      onEndCall={handleEndCall}
      name={aiChatModel.name}
    />
  );
};

export default AiVideoChatActionBarLogic;
