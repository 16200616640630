import styled from "styled-components";
import { useCurrentLoginBackgroundImage } from "../../app/hooks/gyarados.hook";
import { useStore } from "../../app/store";
import { zIndex } from "../../app/style/theme";
import { getImageUrl } from "../../common/util/gyarados.util";
import Hide from "../../componentsLibrary/atoms/Hide";

const Container = styled.div`
  position: absolute;
  z-index: ${zIndex.background};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  /* disable scroll bars for external controls (mouse or keyboards) */
  overflow: hidden;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  background-color: black;
`;

const Blur = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(${(p) => p.theme.blurStrong});
`;

const AiVideoChatBackground: React.FC = () => {
  const backgroundImageSource = useCurrentLoginBackgroundImage();
  const backgroundImageUrl = getImageUrl(backgroundImageSource);
  const currentStep = useStore((s) => s.userFlow.currentStep);

  return (
    <Container>
      {backgroundImageUrl && <Img src={backgroundImageUrl} />}
      <Hide
        width="100%"
        height="100%"
        hide={
          !(
            currentStep === "login:start-action" ||
            currentStep === "experience:ready"
          )
        }
        speed={600}
        style={{ position: "absolute" }}
      >
        <Blur />
      </Hide>
    </Container>
  );
};

export default AiVideoChatBackground;
