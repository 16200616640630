import { useStore } from "../../app/store";
import Hide from "../../componentsLibrary/atoms/Hide";
import AiAvatarLogic from "./AiAvatar.logic";

const AiAvatarPanel: React.FC = () => {
  const visible = useStore((s) => s.layout.panels.aiAvatar.visible);

  return (
    <Hide hide={!visible} unMount>
      <AiAvatarLogic visible={visible} />
    </Hide>
  );
};

export default AiAvatarPanel;
