import {
  FromGameMessage,
  FromGameMessagesSchema,
} from "../../app/gameConnection/messages/fromGameMessages";
import { SliceCreator } from "../../app/store";
import { PanelName } from "../_layout/panels.types";

/**
 * Middleware to handle legacy implementation for loading levels Messages.
 */
const sliceName = "mediaCapture";

export type MediaCaptureAction =
  FromGameMessagesSchema["MediaCaptureAction"]["action"];

export type MediaShareState = {
  url?: string;
  media?: Blob;
  noClose?: boolean;
  previousPanel?: PanelName;
};

export type MediaCaptureState = {
  mediaCaptureAction: FromGameMessagesSchema["MediaCaptureAction"] | undefined;
  setMediaCaptureAction: (
    mediaCaptureAction: FromGameMessagesSchema["MediaCaptureAction"] | undefined
  ) => void;

  mediaShareState: MediaShareState | undefined;
  setMediaShareState: (mediaShareState: MediaShareState | undefined) => void;

  handleMediaCaptureMessage: (message: FromGameMessage) => void;
};

type State = {
  mediaCapture: MediaCaptureState;
};

export const createMediaCaptureSlice: SliceCreator<State> = (set, get) => ({
  mediaCapture: {
    mediaCaptureAction: undefined,
    setMediaCaptureAction: (mediaCaptureAction) =>
      set(
        (state) => {
          state.mediaCapture.mediaCaptureAction = mediaCaptureAction;
        },
        false,
        sliceName + "/setMediaCaptureAction"
      ),

    mediaShareState: undefined,
    setMediaShareState: (sharedMediaState: MediaShareState | undefined) =>
      set(
        (state) => {
          state.mediaCapture.mediaShareState = sharedMediaState;
        },
        false,
        sliceName + "/setMediaShareState"
      ),
    handleMediaCaptureMessage: (message: FromGameMessage) => {
      switch (message.type) {
        case "MediaCaptureAction":
          set(
            (state) => {
              state.mediaCapture.mediaCaptureAction = { ...message };
            },
            false,
            sliceName + "/handleMediaCaptureMessage"
          );
          break;
        case "ShareMedia":
          set(
            (state) => {
              state.mediaCapture.mediaShareState = {
                url: message.url,
                previousPanel: getPanelName(message.mediaType),
                noClose: message.endSession,
              };
              state.layout.openPanel("mediaShare");
            },
            false,
            sliceName + "/handleMediaCaptureMessage"
          );
          get().layout.openPanel("mediaShare");
          break;
        default:
          break;
      }
    },
  },
});

const getPanelName = (mediaType: string) => {
  switch (mediaType) {
    case "image":
      return "photo";
    case "video":
      return "videoCapture";
    default:
      return;
  }
};
