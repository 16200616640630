import { styled } from "styled-components";
import { steps } from "../../../../app/style/theme";
import { getImageUrl } from "../../../../common/util/gyarados.util";
import Image from "../../../../componentsLibrary/atoms/Image";
import Space from "../../../../componentsLibrary/atoms/Space";
import CardPartialPortrait from "../../../../componentsLibrary/molecules/CardPartialPortrait";
import useGetPollElements from "../../../../domains/poll/hooks/useGetPollElements";
import AiVideoChatAvatarImage from "../../login/AiVideoChatAvatarImage";
import { Props } from "./AiVideoChatPoll.ui";

const PollContainer = styled.div`
  padding: 0 ${steps.spacing[6]};
`;

const AvatarWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -1px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const AiVideoChatPollPortrait: React.FC<Props> = ({
  handleDismiss,
  poll,
  aiVideoChatModel,
  testId,
  ...rest
}) => {
  const { Header, Body, Button, Title, Subtitle, ErrorMessage } =
    useGetPollElements({ poll, handleDismiss, ...rest });
  const pollImage = poll?.image;
  return (
    <CardPartialPortrait
      testId={testId}
      padding="0px"
      topSection={
        <>
          {pollImage ? (
            <Image
              src={getImageUrl(pollImage, 700, 700) ?? ""}
              width="100%"
              height="200px"
              cover
            />
          ) : (
            <PollContainer>
              <Space h={8} />
              <AvatarWrapper>
                <AiVideoChatAvatarImage aiVideoChatModel={aiVideoChatModel} />
              </AvatarWrapper>

              {Header}
            </PollContainer>
          )}
          <PollContainer>
            <Space h={6} />
            {Title}
          </PollContainer>
        </>
      }
      onClose={handleDismiss}
    >
      <PollContainer>
        <Space h={5} />
        {Subtitle}
        {Body}
        {Button}
        <Space h={2} />
        {ErrorMessage}
        <Space h={8} />
      </PollContainer>
    </CardPartialPortrait>
  );
};

export default AiVideoChatPollPortrait;
