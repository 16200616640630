import { useLocation } from "react-router-dom";

/** Temporary way of knowing if the environment is for AI Video Chat */
export const useIsAiVideoChat = () => {
  const location = useLocation();
  const isAiVideoChat = location.pathname.includes("aiVideoChat");

  return isAiVideoChat;
};

export default useIsAiVideoChat;
