import Webcam from "react-webcam";
import { css, styled, useTheme } from "styled-components";
import { zIndex } from "../../../../app/style/theme";
import Button from "../../../../componentsLibrary/atoms/Button";
import Hide from "../../../../componentsLibrary/atoms/Hide";
import Icon from "../../../../componentsLibrary/atoms/Icon";
import Typo from "../../../../componentsLibrary/atoms/Typo";
import SpeakingWidget from "../../../../componentsLibrary/molecules/avatarCircle/SpeakingWidget";
import { useText } from "../../../../domains/language/language.hook";
import { INDICATOR_SIZE } from "../../../../domains/videoConference/lib/videoAvatars.constants";
import { Props } from "./AiVideoChatVideo.ui";

const SpeakingIndicatorWrapper = styled.div`
  width: ${INDICATOR_SIZE.SMALL}px;
  height: ${INDICATOR_SIZE.SMALL}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8px;
  bottom: 8px;
  z-index: ${zIndex.overlays + 1};
`;

const NameWrapper = styled.div`
  position: absolute;
  left: 12px;
  bottom: 8px;
`;

const Overlay = styled.div<{ $hide?: boolean }>`
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  ${(p) =>
    !p.$hide &&
    css`
      &:hover {
        opacity: 1;
      }
    `}
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AiVideoChatVideo: React.FC<Props> = ({
  hide,
  webcamMuted,
  micMuted,
  setWebcamMuted,
  videoConstraints,
  isSpeaking,
  onUserMedia,
  height = 180,
  width = 320,
  right = 32,
  isSmall,
}) => {
  const t = useText();
  const theme = useTheme();

  return (
    <Hide
      hide={hide}
      height={`${height}px`}
      width={`${width}px`}
      style={{
        position: "absolute",
        bottom: "32px",
        right: `${right}px`,
        borderRadius: "8px",
        overflow: "hidden",
        background: theme.colorBelow0,
        backdropFilter: `blur(${theme.blurStrong})`,
        height: `${height}px`,
        width: `${width}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: zIndex.overlays,
        pointerEvents: "all",
      }}
    >
      <Hide
        hide={webcamMuted}
        unMount
        height={`${height}px`}
        width={`${width}px`}
      >
        <Webcam
          muted
          mirrored
          height={`${height}px`}
          width={`${width}px`}
          videoConstraints={videoConstraints}
          onUserMedia={onUserMedia}
          style={{ objectFit: "cover" }}
        />
      </Hide>
      <Hide hide={!webcamMuted} unMount style={{ position: "absolute" }}>
        <Icon.Bust size="60px" color={theme.colorBelow1} />
      </Hide>
      <Overlay
        onClick={() => {
          setWebcamMuted(!webcamMuted);
        }}
        $hide={isSmall}
      >
        <Icon.VideoOff color={theme.colorBelow1} size="20px" />
      </Overlay>
      <SpeakingIndicatorWrapper>
        <SpeakingWidget
          micHidden={micMuted || !isSpeaking}
          size={INDICATOR_SIZE.SMALL}
          isSpeaking={!micMuted && isSpeaking}
          isMuted={micMuted}
          toggleMute={() => {}}
        />
        {micMuted && (
          <Button.Glass
            circular
            opaque
            onClick={() => {}}
            override={{
              width: `${INDICATOR_SIZE.SMALL - INDICATOR_SIZE.OFFSET}px`, // workaround for Button.Glass
              height: `${INDICATOR_SIZE.SMALL - INDICATOR_SIZE.OFFSET}px`,
            }}
          >
            <Icon.MicrophoneOffFilled
              size={`${INDICATOR_SIZE.SMALL * 0.7}px`}
              color={theme.colorAbove2}
            />
          </Button.Glass>
        )}
      </SpeakingIndicatorWrapper>
      <NameWrapper>
        <Typo.Body color={theme.colorBelow1}>
          {t("ai_video_chat_you")}
        </Typo.Body>
      </NameWrapper>
    </Hide>
  );
};

export default AiVideoChatVideo;
