import { useEffect, useRef, useState } from "react";
import StreamingAvatar, {
  AvatarQuality,
  StreamingEvents,
} from "@heygen/streaming-avatar";
import { logError, logInfo } from "../../common/util/logger";
import AiAvatarUi from "./AiAvatar.ui";

type Props = {
  visible?: boolean;
};

const AiAvatarLogic: React.FC<Props> = ({ visible }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [avatar, setAvatar] = useState<StreamingAvatar | null>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  async function fetchAccessToken(): Promise<string> {
    const apiKey =
      "YzIxYzE2NjU5MTdkNGVhY2FhMWQ3ODgzOTBiNWQ5NTAtMTc0MjM3NzYzMw==";
    const response = await fetch(
      "https://api.heygen.com/v1/streaming.create_token",
      {
        method: "POST",
        headers: { "x-api-key": apiKey },
      }
    );

    const { data } = await response.json();
    return data.token;
  }

  function handleStreamReady(event: { detail: MediaProvider | null }) {
    if (event.detail && videoRef.current) {
      videoRef.current.srcObject = event.detail;
      videoRef.current.onloadedmetadata = () => {
        videoRef.current?.play().catch((error) => {
          logError("GENERIC", "Failed to play video:", error);
        });
      };
      setIsReady(true);
      logInfo("GENERIC", "Stream is ready");
    } else {
      logError("GENERIC", "Stream is not available");
    }
  }

  function handleStreamDisconnected() {
    logInfo("GENERIC", "Stream disconnected");
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  }

  async function terminateAvatarSession() {
    if (!avatar) return;

    await avatar.stopAvatar();
    if (videoRef.current) videoRef.current.srcObject = null;
    setAvatar(null);
    setIsReady(false);
    logInfo("GENERIC", "Avatar session terminated");
  }

  useEffect(() => {
    async function initializeAvatarSession() {
      try {
        const token = await fetchAccessToken();
        const streamingAvatar = new StreamingAvatar({ token });
        setAvatar(streamingAvatar);
        const data = await streamingAvatar.createStartAvatar({
          quality: AvatarQuality.Medium,
          avatarName: "Shawn_Therapist_public",
          disableIdleTimeout: true,
          language: "en",
          knowledgeId: "b742b5259121482ea018da500d0ead29",
        });

        logInfo("GENERIC", "Session data:", data);

        streamingAvatar.on(StreamingEvents.AVATAR_TALKING_MESSAGE, () => {});
        streamingAvatar.on(StreamingEvents.STREAM_READY, handleStreamReady);
        streamingAvatar.on(
          StreamingEvents.STREAM_DISCONNECTED,
          handleStreamDisconnected
        );

        await startVoiceChat(streamingAvatar);
      } catch (error) {
        logError("GENERIC", "Failed to initialize avatar session:", error);
      }
    }

    if (!avatar) initializeAvatarSession();

    return () => {
      terminateAvatarSession();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar]);

  async function startVoiceChat(avatar: StreamingAvatar) {
    if (!avatar) return;

    try {
      await avatar.startVoiceChat({
        useSilencePrompt: false,
      });
    } catch (error) {
      logError("GENERIC", "Error starting voice chat:", error);
    }
  }

  return <AiAvatarUi visible={visible} videoRef={videoRef} isReady={isReady} />;
};

export default AiAvatarLogic;
