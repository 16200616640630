import { useReflow } from "../../../../common/hooks/ui";
import AiVideoChatActionBarUiNormal from "./AiVideoChatActionBar.ui.normal";
import AiVideoChatActionBarUiSmall from "./AiVideoChatActionBar.ui.small";

export type Props = {
  onToggleSettings: () => void;
  onEndCall: () => void;
  name?: string;
};

const AiVideoChatActionBarUi: React.FC<Props> = (props) => {
  return useReflow({
    normal: <AiVideoChatActionBarUiNormal {...props} />,
    small: <AiVideoChatActionBarUiSmall {...props} />,
  });
};

export default AiVideoChatActionBarUi;
